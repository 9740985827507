import { Component, OnInit, Input, OnChanges, SimpleChanges, Renderer2, ElementRef } from '@angular/core';
import {
  QuestionState,
  IContentElement,
  IEntryStateGroup,
  IContentElementGroup,
  IContentElementGroupTarget,
  ScoringTypes,
  IContentElementDndSub,
  IContentElementDndDraggable,
  getElementWeight,
  GroupingArrangementType,
  ElementType,
  IContentElementMcqOption,
  roundNumeric,
  OrderCombination,
  GroupingTargetContentAlignmentType,
} from '../models';
import { IElementPos, renderDndElementStyle } from '../../ui-item-maker/element-config-grouping/element-config-grouping.component';
import {CdkDragDrop, CdkDragEnter, CdkDropList, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
// import {IContentElementGroup} from '../../ui-item-maker/element-config-grouping/element-config-grouping.component';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import {TextToSpeechService} from "../text-to-speech.service";
import * as _ from 'lodash';
import { PubSubTypes } from '../element-render-frame/pubsub/types';
import { LangService } from 'src/app/core/lang.service';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';

const showGroupingInstr = (element: IContentElementGroup) => {
  return !element.isInstructionsDisabled
}

export const getGroupingInstrSlug = (element:IContentElementGroup) => {
  if(!showGroupingInstr(element)) {
    return "";
  }

  return "txt_default_drag_instr";
}

const SCORING_TYPE = ScoringTypes.AUTO;
@Component({
  selector: 'element-render-grouping',
  templateUrl: './element-render-grouping.component.html',
  styleUrls: ['./element-render-grouping.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class ElementRenderGroupingComponent implements OnInit, OnChanges {
  
  @Input() element:IContentElementGroup;
  @Input() isLocked: boolean;
  @Input() questionState: QuestionState;
  @Input() changeCounter: number;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() isFSA?: boolean;

  draggables: IElementPos[] = [];
  targets: {targetContext: IContentElementGroupTarget, contents:IElementPos[]}[] = [];
  initialNumOptions;
  isDragging:boolean;
  dragTriggers:Map<IElementPos, Subject<boolean>> = new Map();
  dropTriggers:Map<IContentElementGroupTarget, Subject<boolean>> = new Map();
  _isDragstarted;

  constructor(
      public textToSpeech:TextToSpeechService,
      public lang: LangService,
      private renderer: Renderer2,
      private whitelabel: WhitelabelService,
  ) { }

  ngOnInit(): void {
    if (!this.element.targetColourScheme) {
      this.element.targetColourScheme = ({backgroundColor: "#FFFFFF", textColor: "#000000"});
    }
    if (!this.element.draggableColourScheme) {
      this.element.draggableColourScheme = ({backgroundColor: "#FFFFFF", textColor: "#000000"});
    }
    if (!this.element.alignmentType) {
      this.element.alignmentType = GroupingTargetContentAlignmentType.CENTER;
    }
    if (!this.element.bottomMarginEM) {
      this.element.bottomMarginEM = 1.56
    }
    if (!this.element.targetPadding) {
      this.element.targetPadding = 0
    }
    //console.log(this.element.customTargetDim);
    this.ensureState();
    this.loadState();
    let i = 0
    do {
      let numDrags = this.draggables.length;
      this.targets.forEach((targ)=>{
         numDrags += targ.contents.length;
      })
      if (numDrags<this.element.draggables.length && i<3) {
        if (this.isFSA){
          alert(this.lang.tra('alert_grouping_answer_again_FSA'));
        } else {
          alert(this.lang.tra('alert_grouping_answer_again'));
        }
        this.questionState[this.element.entryId] = undefined
        this.draggables = []
        this.targets = []
        this.updateDisplayEls();
        this.ensureState();
        this.loadState();
        i++
      } else {
        break;
      }
    } while(true)
    
  }

  isVoiceoverEnabled() {
    return this.textToSpeech.isActive;
  }
  
  dragStarted(element:IElementPos) {
    const voiceover = element.ref.voiceover; 
    if (voiceover && voiceover.url) {
      this.getDragTrigger(element).next(true);  
    }
    this._isDragstarted = true;
  }

  /*getElementAudio(element: IElementPos): string {
    return (element.ref && element.ref.voiceover) ? element.ref.voiceover.url : '';
  }*/

  getElementAudio(voiceover: any): string {
    return voiceover && voiceover.url ? voiceover.url : '';
  }
  
  getTargetWidth(target) {
    // The Fix Width setting in the target will overite the width in the Target Dimensions setting
    if (target.width) {
      return target.width
    }
    return this.element.targetWidth
  }

  getTargetHeight(target, maxHeight:boolean = false){
    //Take the precedence if Fixed-height is provided 
    if (target.height) return target.height
    // For backward compatibility
    if (maxHeight && this.element.targetHeight && this.element.wrapInTargets) return this.element.targetHeight;
    return maxHeight ? null : this.element.targetHeight
  }

  getDragTrigger(element: IElementPos){
    let trigger = this.dragTriggers.get(element);
    if (!trigger){
      trigger = new Subject();
      this.dragTriggers.set(element, trigger);
    }
    return trigger;
  }

  getDropTrigger(targetContext: IContentElementGroupTarget){
    let trigger = this.dropTriggers.get(targetContext);
    if (!trigger){
      trigger = new Subject();
      this.dropTriggers.set(targetContext, trigger);
    }
    return trigger;
  }  
  
  ensureState(){
    if (this.questionState) {
      if (!this.questionState[this.element.entryId]) {
        let isStarted, isFilled, isCorrect, isResponded;
         isStarted = isFilled = isCorrect = isResponded = false;

        if(this.element.isOptional){
          isStarted = isFilled = true
          isCorrect = undefined;
        }

        let entryState: IEntryStateGroup = {
          type: ElementType.GROUPING,
          isCorrect,
          isStarted,
          isFilled,
          isResponded,
          score: 0, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
          weight: getElementWeight(this.element),
          scoring_type: SCORING_TYPE,
          draggables: this.draggables,
          targets: this.targets
        };
        //console.log("initializing state")
        this.questionState[this.element.entryId] = entryState;
      }
      
    }
  }

  loadState(){
    if (this.questionState) {
      if (this.questionState[this.element.entryId]) {
        //console.log(this.questionState[this.element.entryId])
        this.draggables = this.questionState[this.element.entryId].draggables;
        this.targets = this.questionState[this.element.entryId].targets;
      }
    }
  }

  getDraggableStyle(dragEl:any, includePosition:boolean=false){
    const style:any = {
      color: this.element.draggableColourScheme.textColor,
      backgroundColor: this.element.removeDraggableBgBorder ? 'transparent!': this.element.draggableColourScheme.backgroundColor,
    };
    if (includePosition && (!this._isDragstarted || !this.isManual())){ 
      style.position = this.getPosition();
      if (this.element.topAlignTargets) {
        style["position"] = "absolute"
      }
      style['left.em'] = this.getLeft(dragEl.ref)
      style['top.em'] = this.getTop(dragEl.ref)
    }
    if (this.element.draggableWidth) { style['width.em'] = this.element.draggableWidth; }
    if (this.element.draggableHeight) { style['height.em'] = this.element.draggableHeight; }
    style['border'] = this.element.removeDraggableBgBorder ? 'none' : '1px solid #999'
    style['background-color'] = this.element.removeDraggableBgBorder ? 'transparent': "#fff"
    style['box-shadow'] = this.element.removeDraggableBgBorder ? 'none': '0px 0px 4px rgba(6, 6, 6, 0.3)'
    return style;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.changeCounter){
      //console.log("in ngonchanges")
      this.updateDisplayEls();
      //this.updateState();
    }
  }

  updateDrags() {
    this.draggables = [];
    this.element.draggables.forEach(element => this.addElementToList(element, this.draggables));
    this.initialNumOptions = this.draggables.length;
  }

  updateDisplayEls() {
    this.targets = [];

    this.targets = this.element.targets.map(target => {
      return {
        targetContext: target,
        contents: [],
      }
    });
    this.updateDrags();
    // console.log('this.targets', this.targets)
  }

  addElementToList(element: IContentElementDndSub, elementsToPosition: IElementPos[], isTarget: boolean= false) {
    let hasElement  = false;
    if ((<IContentElementDndDraggable> element).element) {
      hasElement = true;
    }
    elementsToPosition.push({
      ref: element,
      originalX: element.x,
      originalY: element.y,
      isTarget,
      style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
    });
    return
  }

  updateState(){
    const weight = getElementWeight(this.element); 
    // type DragIds = Set<number>
    // let targetsDragMap: Map<number , DragIds> = new Map()
    let score = 0; //this.initialNumOptions;
    let totalPartialScore = 0;
    let numMatched = 0;
    let isAnyFilled = false;
    let isAllFilled = true;
    let numFilled = 0;
    let numPlaced = 0;
    let isCorrect = false;
    let groupSize = this.element.isGroupSizeLimited ? this.element.groupSize : undefined;
    const numDraggables = this.element.draggables.length;
    // are any of the targets filled? are they all filled?
    this.targets.forEach(target=>{
      numPlaced += target.contents.length;
      if (target.contents.length > 0){
        isAnyFilled = true;
        numFilled++;
      }
    })
    
    if (numPlaced < numDraggables){
        isAllFilled = groupSize * this.targets.length === numPlaced ? true : false
    }
    // identify all placements into known targets (map to target's ID)
    if (!this.element.isTargetsSame && !this.element.isTargetsOrdered) {
      if (!this.element.isOptionsReusable) {
        const knownDraggablePlacements = new Map();
        this.targets.forEach(target=>{
          const targetId = target.targetContext.id;
          target.contents.forEach(draggableEl => {
            const draggableRef = draggableEl.ref;
            const draggableRefStr =  JSON.stringify(draggableRef);
            knownDraggablePlacements.set(draggableRefStr, targetId)
          })
        })
        // count up the elements that are in the correct position (assumes that all elements should have a target position, this can be updated later to allow for draggables that do not have an intended target)
        this.element.draggables.forEach(draggableRef => {
          const draggableRefStr = JSON.stringify(draggableRef)
          const placedDraggable = knownDraggablePlacements.get(draggableRefStr);
          if (draggableRef.id == placedDraggable || (!draggableRef.id && !placedDraggable) ){
            numMatched++;
            totalPartialScore += draggableRef.partialWeight || 0
          }
        })
        numMatched = Math.min(numMatched, numDraggables);
        // finalize score
        isCorrect = (numMatched == numDraggables);
        if (this.element.enableProportionalScoring) {
          score = weight * numMatched/numDraggables;
        }
        // else{
        //   score = isCorrect ? weight : 0;
        // }
      } else {
        isCorrect = true;
        this.targets.forEach(target=>{
          const numDragsEach = {}
          target.contents.forEach(draggableEl=>{
            const draggableRef = draggableEl.ref
            const dragID = draggableRef.id
            if (numDragsEach[dragID] && numDragsEach[dragID]>=0) {
              numDragsEach[dragID]++
            } else {
              numDragsEach[dragID] = 1
            }
          })
          this.element.draggables.forEach(draggableRef=>{
            const drag = draggableRef;
            let num1 = numDragsEach[drag.id]
            if (!num1) num1 = 0;
            let num2 = draggableRef.targetID2Amount[target.targetContext.id]
            if (!num2) num2 = 0;
            if (num1!=num2) {
              isCorrect=false;
            } 
          })
        })
      }
    } else if (this.element.isTargetsOrdered) {
      let correct = 0;
      const orders:OrderCombination[] = JSON.parse(JSON.stringify(this.element.correctOrders))
      const indicesUsed = new Map()
      this.targets.forEach((target)=>{
        let index = -1
        orders.forEach((order, i)=>{
          if (index!=-1 || indicesUsed.get(i)) return
          // console.log(order.orderTarget)
          if ((order.orderTarget)  && target.targetContext.id!=order.orderTarget) return
          const arrOrder = order.order.split(',')
          if (arrOrder.length!=target.contents.length) {
            return
          }
          arrOrder.forEach((val, index)=>{
            arrOrder[index] = val.trim()
          })
          let isEqual = true
          arrOrder.forEach((val, index)=>{
            //console.log(val, target.contents[index].ref.id)
            if (val!=String(target.contents[index].ref.id)) {
              //console.log("not equal")
              isEqual = false
            }
          })
          if (isEqual) {
            index = i
          }
        })
        if (index!=-1) {
          indicesUsed.set(index, true)
          correct += 1
        }
      })
      if (correct == this.targets.length) {
        isCorrect = true
      } else {
        isCorrect = false
      }
    } else {
      let correct = 0;
      const lookForCombos = [];
      if (this.element.correctCombinations) {
        this.element.correctCombinations.forEach((combo)=>{
          lookForCombos.push(JSON.parse(JSON.stringify(combo)))
        })
      }


      let id2amountBase = {}
      this.targets.forEach((targ)=>{
        targ.contents.forEach((drag)=>{
          if (drag.ref.id) {
            id2amountBase[drag.ref.id]=0
          }
        })
      })
      this.draggables.forEach((drag)=>{
        if (drag.ref.id) {
          id2amountBase[drag.ref.id]=0
        }
      })
      this.targets.forEach((targ)=>{
        const id2amount = JSON.parse(JSON.stringify(id2amountBase));
        targ.contents.forEach((drag)=>{
          if (!id2amount[drag.ref.id]) {
            id2amount[drag.ref.id]=1;
          } else {
            id2amount[drag.ref.id]++;
          }
        })
        this.draggables.forEach((drag)=>{
          const anID = drag.ref.id
          if (!id2amount[anID]) {
            id2amount[anID]=0;
          }
        })
        for (let i = 0;i<lookForCombos.length;i++) {
          let mapping = lookForCombos[i].id2amount;
          if (mapping && _.isEqual(mapping, id2amount)) {
            correct++
            lookForCombos.splice(i, 1)
            break;
          }
        }
      })
      if (correct==this.targets.length) {
        score = weight
        isCorrect = true;
      }
    }
    if (!score || !this.element.enableProportionalScoring){
      score = isCorrect ? weight : this.element.isPartialWeightsEnabled ? roundNumeric(totalPartialScore)  : 0;
    }
    // conclude
    let isFilled:boolean;
    let isStarted = isAnyFilled;
    if ( this.element.reqMinimumPlacedTarget && this.element.reqMinimumPlacedTarget > 0){
      if (!this.element.perGroupCheck) {
        isFilled = numPlaced >= this.element.reqMinimumPlacedTarget ? true : false
      } else {
        isFilled = true
        if (this.element.reqMinimumPlacedTarget && this.element.reqMinimumPlacedTarget>0) {
          this.targets.forEach((targ)=>{
            if (targ.contents.length<this.element.reqMinimumPlacedTarget) {
              isFilled = false
            }
          })
        }
      }
      
    } else {
      isFilled = groupSize ? isAllFilled : isAnyFilled
    }

    let isScoringDisabled = false
    //check for isOptional
    if(this.element.isOptional){
      score = 0;
      //isStarted = true
      isFilled = true;
      isCorrect = undefined;
      isScoringDisabled = true
    }
        
    let entryState: IEntryStateGroup = {
      type: ElementType.GROUPING,
      isCorrect,
      isStarted,
      isFilled: isFilled,
      isResponded: isAnyFilled && !isScoringDisabled,
      isOptionalResponded: isAnyFilled && isScoringDisabled,
      score, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
      weight,
      scoring_type: SCORING_TYPE,
      draggables: this.draggables,
      targets: this.targets,
      isScoringDisabled,
      isTargetsOrdered: !!this.element.isTargetsOrdered
    };
    if (this.questionState){
      this.questionState[this.element.entryId] = entryState;
    }
    if(this.questionPubSub){
      this.questionPubSub.allPub({entryId: this.element.entryId, type: PubSubTypes.UPDATE_VALIDATOR, data: {}})
    }
  }

  getTargetColor(target){
    if (target.targetContext.bgColor){
      return target.targetContext.bgColor
    }
    else {
      const targetColourScheme = this.element.targetColourScheme
      if (targetColourScheme && targetColourScheme.backgroundColor){
        return targetColourScheme.backgroundColor;
      }
    }
  }

  keyboardDrop:any ={
    previousContainerId: "",
    previousSrcIndex: null,
    selectedDraggable: {},
    source: [],
  }

  private _resetKeyboardSelection() {
    this.keyboardDrop.previousSrcIndex= null;
    this.keyboardDrop.selectedDraggable = {}
    this.keyboardDrop.source = [];
  }

  private _setKeyBoardSelection(source, elPos, index, id ){
    this.keyboardDrop.previousSrcIndex= index;
    this.keyboardDrop.selectedDraggable = elPos
    this.keyboardDrop.source = source;
    this.keyboardDrop.previousContainerId = id
  }
  
  isSelected(draggable){
    return this.keyboardDrop.source.length && this.keyboardDrop.selectedDraggable === draggable
  }

  onEnterContainer(e, targetContext, target, isHomeDest:boolean=false){
    //console.log("container",e,target)
    if (this.isLocked || this.isDragging || this.draggableIsMoving) return;
    if(this.keyboardDrop.source.length){
      const {source, previousSrcIndex } = this.keyboardDrop
      const dest = target //_.has(target,'contents') ? target.contents : target 
      const currentIndex = dest.length
      this._drop({source, dest, previousIndex:previousSrcIndex, currentIndex}, targetContext, isHomeDest, null)
    }
    this.keyboardDrop.previousContainerId = e.srcElement.id
    this._resetKeyboardSelection()
  }
  
  onEnter(e, targetContext, index:number, target){
    e.stopPropagation();
    if (this.isLocked || this.draggableIsMoving) return;
    let dropObj = this.keyboardDrop
    const containerElementId = e.srcElement.parentElement.id
    const sameContainer =  containerElementId === dropObj.previousContainerId

    if(/*this.dragTriggers.get(targetContext)*/true) {

      if(sameContainer && dropObj.source.length) {
        if(_.isEmpty(target)) {
          if(dropObj.selectedDraggable === targetContext){
            this._resetKeyboardSelection();
            return;
          }
          const source = _.isEmpty(target) ? this.draggables : target.contents
          this._setKeyBoardSelection(source, targetContext, index, containerElementId)
          return;
        }
        // Move the draggable in same container
        moveItemInArray(target.contents, dropObj.previousSrcIndex, index);
        this._resetKeyboardSelection()
        return;
      } 

      const source = _.isEmpty(target) ? this.draggables : target.contents 
      this._setKeyBoardSelection(source, targetContext, index, containerElementId)
      
    }
    // console.log(this.keyboardDrop)
  }

  drop(event: CdkDragDrop<string[]>, targetContext: IContentElementGroupTarget, isHomeDest:boolean=false) {
    this.draggableIsMoving = false;
    // console.log(event.previousContainer)
    // console.log(event.previousContainer.data)
    // console.log(event.previousIndex)
    const source = event.previousContainer.data;
    const dest = event.container.data;
    const previousIndex = event.previousIndex;
    const currentIndex = event.currentIndex;

    this._drop({source, dest, previousIndex, currentIndex}, targetContext, isHomeDest, event)
    this._resetKeyboardSelection()
    this.keyboardDrop.previousContainerId = "";
  }

  isMaxAnswerMsgShown;
  checkIfMaxAnswersReached() {
    return this.isMaxAnswerMsgShown;
  }

  turnMaxMsgOff() {
    this.isMaxAnswerMsgShown = false
  }

  getMaxMsgText() {
    if (this.whitelabel.getSiteFlag("IS_BCED")){
      return this.lang.tra('alert_grouping_too_many_added')
    }
    return "You've added too many objects. Drag one out of the box if you wish to change it and drag in a new object. When you are happy with your choices, click Next."
  }

  getClickToDismissMsg() {
    return "Click this notification to dismiss."
  }

  isNotBCFrench() {
    return !(this.whitelabel.getSiteFlag('IS_BCED') && this.lang.getCurrentLanguage() == 'fr')
  }

  private _drop(dropLocation, targetContext: IContentElementGroupTarget, isHomeDest:boolean=false, event: CdkDragDrop<string[]> | null) {
    // Event must be empty for keyboard drop
    const { source, dest, previousIndex, currentIndex } = dropLocation;
    if (isHomeDest)
      if(this.element.isGroupSizeLimited && source.length <= this.element.groupSize)
        this.isMaxAnswerMsgShown = false
      
    if (!isHomeDest)
      if(this.element.isGroupSizeLimited && dest.length >= this.element.groupSize){
        this.isMaxAnswerMsgShown = true
        return
      }

    if (!_.isEmpty(event) && !event.isPointerOverContainer) {
      this.sendHome(source, previousIndex);
      return; 
    }

    // For Keyboard drop we are transfer items in the same container in the onEnter() not here:
    if (event !== null && event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } 
    else {
      let allowTransfer;
      if (this.element.isMatchingMode){
        allowTransfer = false;
        if (isHomeDest){
          allowTransfer = true;
        }
        else if (dest.length === 0) { 
          allowTransfer = true;
        }
      }
      else{
        allowTransfer = true;
      }
      if(targetContext && targetContext['limit'] && targetContext.limit <= dest.length ){
        allowTransfer = false
      }
      if (allowTransfer){
        transferArrayItem(source, dest, previousIndex, currentIndex);
      }
    }
    if (this.element.isOptionsReusable) {
      this.updateDrags();
    }
    // console.log(event, this.targets);
    
    if (targetContext) {
      const voiceover = targetContext.voiceover;
      if (voiceover && voiceover.url) {
        this.getDropTrigger(targetContext).next(true);
      }
    }
    
    this.updateState();
    this.rearrangeByOrder();
    this.removeSelection();
  }

  getDefaultImage(assignedElement) {
    if (assignedElement.ref.element.images) return assignedElement.ref.element.images['default']
  }

  rearrangeByOrder() {
    const thisOpts = this.draggables;
    const configOpts = this.element.draggables;
    const newThisOpts = []
    configOpts.forEach((opt)=>{
      thisOpts.forEach((currOpt)=>{
        if (currOpt.ref.id == opt.id && currOpt.ref.key_id == opt.key_id) {
          newThisOpts.push(currOpt)
        }
      })
    })
    this.draggables = newThisOpts;
  }

  removeSelection() {
    if (window.getSelection()) {
      window.getSelection().removeAllRanges()
    } else if (document.getSelection()) {
      document.getSelection().removeAllRanges();
    }
  }

  // rearrangeByOrder() {
  //   const thisOpts = this.draggables;
  //   const configOpts = this.element.draggables;
  //   const newThisOpts = []
  //   configOpts.forEach((opt)=>{
  //     thisOpts.forEach((currOpt)=>{
  //       if (currOpt.ref == opt) {
  //         newThisOpts.push(currOpt)
  //       }
  //     })
  //   })
  //   this.draggables = newThisOpts;
  // }

  getTargetMargins() {
    if (this.element.arrangementType == GroupingArrangementType.NORMAL) {
      if (!this.element.isRenderDelimiter && !this.element.bottomMarginEM) {
        return "0 25px " + this.element.bottomMarginEM + "em 0";
      } else {
        return "0 0 " + this.element.bottomMarginEM + "em 0";
      }
    } else {
      return "0 0 0 0"
    }
  }

  isManual() {
    return this.element.arrangementType == GroupingArrangementType.MANUAL
  }

  getPosition() {
    if (this.element.arrangementType != GroupingArrangementType.NORMAL) {
      return "relative"
    } else {
      return "static"
    }
  }

  getLeft(el) {
    if (this.element.arrangementType == GroupingArrangementType.MANUAL) {
      return el.x;
    }
    return 0;
  }

  getTop(el) {
    if (this.element.arrangementType == GroupingArrangementType.MANUAL) return el.y;
    return 0;
  }

  getFlexFlowForGroup() {
    if (this.element.arrangementType == GroupingArrangementType.SIDE_BY_SIDE) {
      return "row wrap";
    }
  }

  isSideBySide() {
    return this.element.arrangementType==GroupingArrangementType.SIDE_BY_SIDE;
  }

  getStyleForTarget(el:IContentElementGroupTarget) {
    const style:any = {};
    if (el.backgroundImg && el.backgroundImg.url) {
      style["background-image"]="url(\'"+el.backgroundImg.url+"\')"
      style["background-size"]="100% 100%"
    }
/*
    if (this.element.isTargetListHorizontal) {
      style["flex-flow"]="row nowrap"
      style["justify-content"]="center"
    }*/
    return style;
  }

  getStyleForGroupContainer() {
    const style:any = {}
    if (this.element.arrangementType == GroupingArrangementType.SIDE_BY_SIDE) {
      style["display"]="flex"
      style["wrap"]="nowrap"
    }
    return style;
  }

  getDDSourceStyle() {
    const style = {}
    if (this.element.isDragContainerWidthSet && this.element.dragContainerWidth) {
      style["width"] =  this.element.dragContainerWidth + 'em'
    }
    return style;
  }

  getContentsOrdered(target) {
    if (this.element.sortDraggablesInGroups) {
      target.contents.sort(function(a, b) {
        return a.ref.sortOrder - b.ref.sortOrder;
      })
    }

    return target.contents
  }

  showInstr() {
    return showGroupingInstr(this.element);
  }

  getInstrSlug() {
    return getGroupingInstrSlug(this.element);
  }

  draggableIsMoving = false;
  dragging(e){
    this.draggableIsMoving = true;
    // #Imp: If condition to make sure block only fire once. 
    if(this._isDragstarted){
      // Reason: Drag preview and oiriginal element are opposite in high contrast mode.
      const preview = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag.cdk-drag-preview'));
      if(preview.nativeElement && this.isHighContrastDragPreviewException() ){
        this.renderer.addClass(preview.nativeElement, 'is-inverted-hi-contrast');
      }

      this.checkImageSubTextFont(e);
      this.checkImageHCInversion(preview);
      this._isDragstarted = false
    }
  }

  /**
   * Check if the element being dragged is a image component with sub text, if so, ensure the 
   * font of the sub text in the drag preview is the same as the draggable element.
   * @param e The drag event
   */
  checkImageSubTextFont(e){
    const preview = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag.cdk-drag-preview'));
    if(preview.nativeElement){
      const previewImageSubTextEl = preview.nativeElement.querySelector<HTMLElement>('.image-sub-text')
      if (previewImageSubTextEl) this.syncImageSubTextFontOnDragging(e.source?.element, previewImageSubTextEl)
    }
  }

  /**
   * This method ensures the font inside the image sub text is matches the font set inside the image block.
   * To avoid font changes when the draggable is being dragged.
   * @param draggableEl The draggable element currently being dragged.
   * @param previewImageSubTextEl The preview element of the draggable rendered on the DOM by cdk drag & drop
   * @returns 
   */
  syncImageSubTextFontOnDragging(draggableEl: ElementRef<HTMLElement>, previewImageSubTextEl: HTMLElement){
    if (!draggableEl) return;
    const subText = draggableEl.nativeElement.querySelector<HTMLElement>('.image-sub-text');
    if (subText && subText.style?.fontFamily && subText.style.fontFamily != ''){
      previewImageSubTextEl.style.fontFamily = subText.style.fontFamily;
    } else {
      previewImageSubTextEl.style.fontFamily = 'Source Sans Pro';
    }
  }

  /**
   * Ensures the image is inverted correctly in the drag preview if the original image has avoid inversion on HC turned on
   * @param preview The drag preview element
   */
  checkImageHCInversion(preview: ElementRef<HTMLElement>){
    // If the draggable is a image, and the image element has avoid inversion on HC turned on, restore it (CDK drag preview will lose the filter applied by the setting)
    const previewImageEl = preview.nativeElement.querySelector<HTMLElement>('render-image');
    if (this.textToSpeech.isHiContrast && previewImageEl && previewImageEl.children[0] && previewImageEl.children[0].getAttribute('style').includes('filter: invert(1)')) {
      previewImageEl.style.filter = "invert(1)";
    } 
  }

  dropListDraggablesMoved(e){
    this.draggableIsMoving = true;

    this.checkImageSubTextFont(e);
    this._isDragstarted = false;
    
  }

  isHighContrastDragPreviewException(){
    return this.element.isNoInvertDragPreview && this.textToSpeech.isHiContrast;
  }

  getTargetPadding(){
    return this.element.targetPadding
  }

  sendHome = (source, previousIndex) => {
    this.draggables.indexOf(source);
    transferArrayItem(source, this.draggables, previousIndex, this.draggables.length)
    if (this.element.isOptionsReusable) {
      this.updateDrags();
    }    
    this.updateState();
    this.rearrangeByOrder();
    this.removeSelection();
  }
}
